<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="container">
      <div class="wrapper">
        <div class="content-wrapper">
          <div class="card-body">
            <div class="card pt-2 p-2">
              <div class="row">
                <div class="col-md-10">
                  <h3 style="font-size: 15px; text-align: center">
                    <strong> DESPACHO PLANTA EXTERNA </strong>
                  </h3>
                </div>
                <div class="col-md-2 float-md-right">
                  <h6>
                    <span class="badge badge-warning" v-if="estadoTicket == 3"
                      >Estado: Sin Imprimir</span
                    >
                    <span class="badge badge-warning" v-if="estadoTicket == 1"
                      >Estado: Creada</span
                    >
                    <span class="badge badge-warning" v-if="estadoTicket == 4"
                      >Estado: Cerrada</span
                    >
                  </h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mt-4">
                  <img
                    src="/img/frontera-pegaso-ermes.png"
                    style="width: 280px"
                  />
                </div>
                <div class="col-md-8">
                  <div class="row float-right">
                    <div class="col-md-3" v-if="formEnvio.numero_ticket">
                      <label for="nTicket">N° TICKET</label>
                      <input
                        disabled="disabled"
                        v-model="formEnvio.numero_ticket"
                        style="
                          color: #ff0000;
                          font-weight: bold;
                          font-size: 12px;
                        "
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="col-md-9">
                      <label for="producto">TIPO PRODUCTO</label>
                      <br />
                      <select
                        class="form-control form-control-sm"
                        id="tipo_prod"
                        style="font-size: 10px"
                        v-model="formEnvio.tipo_producto"
                        :class="
                          $v.formEnvio.tipo_producto.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoTicket !== 3"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="op in listasForms.tipo_producto"
                          :key="op.numeracion"
                          :value="op.numeracion"
                        >
                          {{ op.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light pt-2 p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-9">
                      <label for="fecha_cargue">LUGAR Y FECHA DE CARGUE</label>
                      <div class="row ml-1">
                        <input
                          v-model="form.ciudadOrigen"
                          type="text"
                          style="font-size: 12px"
                          placeholder="Lugar"
                          class="form-control form-control-sm col-md-4 mr-3"
                          disabled="disabled"
                        />
                        <input
                          type="date"
                          v-model="fechasForm.cargue.fecha"
                          placeholder="Fecha Expedición"
                          class="form-control form-control-sm col-md-4 mr-3"
                          @change="
                            getFechaFormEnvio(fechasForm.cargue, 'fecha_cargue')
                          "
                        />
                        <input
                          type="text"
                          class="form-control form-control-sm col-md-3"
                          v-model="fechasForm.cargue.hora"
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          :class="
                            $v.fechasForm.cargue.hora.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="
                            getFechaFormEnvio(fechasForm.cargue, 'fecha_cargue')
                          "
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label for="campo_productor">PRODUCTO</label>
                      <select
                        class="form-control form-control-sm"
                        style="font-size: 13px"
                        id="prod_liquid"
                        v-model="formEnvio.producto_id"
                        :class="
                          $v.formEnvio.producto_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoTicket !== 3"
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="pl in listasForms.producto_transportado"
                          :key="pl.id"
                          :value="pl.id"
                        >
                          {{ pl.nombre }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-6">
                      <label for="lugar_origen">LUGAR DE ORIGEN</label>
                      <v-select
                        :class="[
                          $v.formEnvio.sitio_origen_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="sitioOrigen"
                        placeholder="Sitio Origen"
                        label="nombre"
                        :options="listasForms.sitiosOrigenes"
                        @search="buscarSitioOrigen"
                        @input="selectSitioOrigen()"
                        style="font-size: 13px; background-color: #fff"
                        class="form-control form-control-sm p-0"
                        :disabled="accion == 'Show' && estadoTicket !== 3"
                      ></v-select>
                      <div class="error" v-if="!formEnvio.sitio_origen_id">
                        Seleccione sitio de origen
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label for="fecha_cita_cargue">CITA DE CARGUE</label>
                      <input
                        type="datetime"
                        v-model="formEnvio.fecha_cita_cargue"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        disabled
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="fecha_llegada_cargue"
                        >FECHA LLEGADA SITIO CARGUE</label
                      >
                      <input
                        type="date"
                        v-model="fechasForm.llegada_cargue.fecha"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.fecha_llegada_cargue.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @change="
                          [
                            getFechaFormEnvio(
                              fechasForm.llegada_cargue,
                              'fecha_llegada_cargue'
                            ),
                            validarFechas(),
                          ]
                        "
                      />
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        style="font-size: 13px"
                        v-model="fechasForm.llegada_cargue.hora"
                        :class="
                          $v.formEnvio.fecha_llegada_cargue.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        v-mask="{
                          mask: '99:99',
                          hourFormat: '24',
                        }"
                        @change="
                          [
                            getFechaFormEnvio(
                              fechasForm.llegada_cargue,
                              'fecha_llegada_cargue'
                            ),
                            validarFechas(),
                          ]
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-6">
                      <label for="lugar_destino">LUGAR DE DESTINO</label>
                      <v-select
                        :class="[
                          $v.formEnvio.sitio_destino_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                        ]"
                        v-model="sitioDestino"
                        placeholder="Sitio Destino"
                        label="nombre"
                        :disabled="
                          formEnvio.sitio_origen_id == null ||
                            (accion == 'Show' && estadoTicket !== 3)
                        "
                        :options="listasForms.sitiosDestinos"
                        @search="buscarSitioDestino"
                        @input="selectSitioDestino()"
                        style="font-size: 13px; background-color: #fff"
                        class="form-control form-control-sm p-0"
                      ></v-select>
                      <div class="error" v-if="!formEnvio.sitio_origen_id">
                        Seleccione sitio de origen
                      </div>
                      <div
                        class="error"
                        v-else-if="!formEnvio.sitio_destino_id"
                      >
                        Seleccione sitio de destino
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label for="hora_vigencia">HORAS DE VIGENCIA</label>
                      <input
                        v-model="formEnvio.horas_vigencia"
                        type="text"
                        style="font-size: 13px"
                        placeholder="Horas Vigencia"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                    </div>
                    <div class="col-md-2">
                      <label for="hora_adicional">HORA ADICIONAL</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="formEnvio.hora_adicional"
                        :disabled="
                          !formEnvio.horas_vigencia ||
                            (accion == 'Show' && estadoTicket !== 3)
                        "
                      >
                        <option value>Seleccione...</option>
                        <option
                          v-for="horaAdicional in listasForms.horasAdicionales"
                          :key="horaAdicional.descripcion"
                          :value="horaAdicional.descripcion"
                        >
                          {{ horaAdicional.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-8">
                      <label for="direccion_destino">DIRECCION:</label>
                      <input
                        type="text"
                        v-model="formEnvio.direccion_destino"
                        style="font-size: 13px"
                        placeholder="Dirección"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="campo_productor">CIUDAD</label>
                      <input
                        type="text"
                        v-model="form.ciudadDestino"
                        style="font-size: 13px"
                        placeholder="Ciudad"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-8">
                      <label for="conductor">NOMBRE DEL CONDUCTOR</label>
                      <input
                        type="text"
                        v-model="conductor.nombre"
                        style="font-size: 13px"
                        placeholder="Nombre Conductor"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                    </div>
                    <div class="col-md-4">
                      <label for="campo_productor">CEDULA</label>
                      <input
                        type="text"
                        v-model="formEnvio.cedula_conductor"
                        style="font-size: 13px"
                        placeholder="Cedula Conductor"
                        label="cedula"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-6">
                      <label for="empresa_transportadora"
                        >EMPRESA TRANSPORTADORA</label
                      >
                      <input
                        type="text"
                        v-model="transportadora.razon_social"
                        style="font-size: 13px"
                        placeholder="Empresa Transportadora"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="cabezote">PLACAS DEL CABEZOTE</label>
                      <input
                        type="text"
                        v-model="vehiculo.placa"
                        style="font-size: 13px"
                        placeholder="Placa Vehículo"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                      <div class="error" v-if="vehiculo.placa">
                        <b v-if="form.vehiculo.tipo_vehiculo !== null"
                          >Tipo Vehiculo:
                          {{ form.vehiculo.tipo_vehiculo.nombre }}</b
                        >
                        <b v-else>Sin tipo asignado</b>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label for="remolque">PLACAS DEL TANQUE</label>
                      <input
                        type="text"
                        v-model="remolque.placa"
                        style="font-size: 13px"
                        placeholder="Placa Remolque"
                        class="form-control form-control-sm"
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light pt-2 p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-3">
                      <label for="barriles">BARRILLES CARGUE</label>
                      <div class="input">
                        <input
                          type="number"
                          v-model="formEnvio.barriles_cargue"
                          style="font-size: 13px"
                          class="form-control form-control-sm"
                          :class="
                            $v.formEnvio.barriles_cargue.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @blur="validarBarriles"
                          :disabled="accion == 'Show' && estadoTicket !== 3"
                        />
                      </div>
                      <div class="error">
                        <b>Maximos barriles: {{ max_barriles }}</b>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <label for="porcSolido">% SOLIDOS</label>
                      <input
                        type="number"
                        v-model="formEnvio.porc_solidos"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.porc_solidos.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoTicket !== 3"
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="porc_crudo">% CRUDO</label>
                      <input
                        type="number"
                        v-model="formEnvio.porc_crudo"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.porc_crudo.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoTicket !== 3"
                      />
                    </div>
                    <div class="col-lg-3">
                      <label for="porc_agua_libre">% AGUA LIBRE</label>
                      <input
                        type="number"
                        v-model="formEnvio.porc_agua_libre"
                        style="font-size: 13px"
                        class="form-control form-control-sm"
                        :class="
                          $v.formEnvio.porc_agua_libre.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        :disabled="accion == 'Show' && estadoTicket !== 3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="col-md-12">
                  <div class="row" style="font-size: 12px">
                    <div class="col-md-12">
                      <label for="sellos">SELLOS</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="formEnvio.sellos"
                        label="numero"
                        :options="listasForms.sellos"
                        style="font-size: 12px; background-color: #fff"
                        @input="buscarSellos()"
                        placeholder="Sellos"
                        multiple
                        :disabled="accion == 'Show' && estadoTicket !== 3"
                      ></v-select>
                      <div class="error" v-if="formEnvio.sellos">
                        Seleccione los Sellos
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="observaciones">OBSERVACIONES</label>
                    <textarea
                      v-model="formEnvio.observaciones"
                      cols="30"
                      rows="4"
                      style="font-size: 13px; background-color: #fff"
                      class="form-control form-control-sm"
                      :class="
                        $v.formEnvio.observaciones.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      :disabled="accion == 'Show' && estadoTicket !== 3"
                    ></textarea>
                    <div
                      style="font-size: 13px"
                      class="error"
                      v-if="!$v.formEnvio.observaciones.required"
                    >
                      Ingrese una Observación
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-box bg-light p-2">
                <div class="row" style="font-size: 12px">
                  <div class="col-md-4">
                    <label for="fecha_salida_cargue"
                      >FECHA SALIDA SITIO CARGUE</label
                    >
                    <input
                      type="date"
                      v-model="fechasForm.salida_cargue.fecha"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      @change="
                        [
                          getFechaFormEnvio(
                            fechasForm.salida_cargue,
                            'fecha_salida_cargue'
                          ),
                          validarFechas(),
                        ]
                      "
                    />
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="fechasForm.salida_cargue.hora"
                      :class="
                        $v.fechasForm.salida_cargue.hora.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      @change="
                        [
                          getFechaFormEnvio(
                            fechasForm.salida_cargue,
                            'fecha_salida_cargue'
                          ),
                          validarFechas(),
                        ]
                      "
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="fecha_llegada_campo"
                      >FECHA LLEGADA A SITIO DESCARGUE</label
                    >
                    <input
                      type="date"
                      v-model="fechasForm.llegada_campo.fecha"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled="disabled"
                      @change="
                        getFechaFormEnvio(
                          fechasForm.llegada_campo,
                          'fecha_llegada_campo'
                        )
                      "
                    />
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="fechasForm.llegada_campo.hora"
                      :class="
                        $v.fechasForm.llegada_campo.hora.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      disabled="disabled"
                      @change="
                        getFechaFormEnvio(
                          fechasForm.llegada_campo,
                          'fecha_llegada_campo'
                        )
                      "
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="fecha_salida_campo"
                      >FECHA SALIDA DE SITIO DESCARGUE</label
                    >
                    <input
                      type="date"
                      v-model="fechasForm.salida_campo.fecha"
                      style="font-size: 13px"
                      class="form-control form-control-sm"
                      disabled="disabled"
                      @change="
                        getFechaFormEnvio(
                          fechasForm.salida_campo,
                          'fecha_salida_campo'
                        )
                      "
                    />
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      style="font-size: 13px"
                      v-model="fechasForm.salida_campo.hora"
                      :class="
                        $v.fechasForm.salida_campo.hora.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-mask="{
                        mask: '99:99',
                        hourFormat: '24',
                      }"
                      disabled="disabled"
                      @change="
                        getFechaFormEnvio(
                          fechasForm.salida_campo,
                          'fecha_salida_campo'
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-2">
                  <button class="btn bg-gray-dark col-md-11" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
                <div class="col-md-2">
                  <button
                    class="btn bg-navy col-md-11"
                    v-show="!$v.formEnvio.$invalid"
                    @click="saveDetTicket()"
                    :disabled="accion == 'Show' && estadoTicket !== 3"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>
                </div>
                <div class="col-md-2">
                  <a
                    id="botonImp"
                    type="button"
                    class="btn bg-cyan col-md-11"
                    @click="print()"
                    v-show="accion == 'Show' || accion == 'Actualizar'"
                  >
                    <i class="fas fa-file-pdf"></i>
                    <br />Imprimir Ticket
                  </a>
                </div>
                <div class="col-md-2">
                  <a
                    type="button"
                    class="btn bg-cyan col-md-11"
                    @click="printBono(form.id)"
                    v-show="form.bono && accion == 'Show'"
                  >
                    <i class="fas fa-print"></i>
                    <br />Imprimir Bono
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import moment from "moment";
import Loading from "../../../../components/Loading";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
const dateFormat = helpers.regex(
  "dateFormat",
  /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])\s([0-1]?[0-9]|2[0-3]):([0-1]?\d|[0-5][0-9])(?::([0-5]?\d))?(?::([0-5]?\d))?$/
);

export default {
  name: "DetTicketsForm",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      form: {
        ciudadDestino: null,
        ciudadOrigen: null,
        numeroGuia: null,
        bono: null,
      },
      fechasForm: {
        cargue: {
          fecha: null,
          hora: null,
        },
        llegada_cargue: {
          fecha: null,
          hora: null,
        },
        salida_cargue: {
          fecha: null,
          hora: null,
        },
        llegada_campo: {
          fecha: null,
          hora: null,
        },
        salida_campo: {
          fecha: null,
          hora: null,
        },
      },

      formEnvio: {
        id: null,
        tipo_producto: null,
        ciudad_origen_id: null,
        numero_ticket: null,
        fecha_cargue: null,
        conductor_id: null,
        vehiculo_id: null,
        remolque_id: null,
        transportadora_id: null,
        producto_id: null,
        tipo_vehiculo_id: null,
        ciudad_destino_id: null,
        direccion_destino: null,
        sitio_origen_id: null,
        sitio_destino_id: null,
        horas_vigencia: null,
        hora_adicional: null,
        sellos: [],
        user_id: null,
        porc_solidos: null,
        porc_crudo: null,
        porc_agua_libre: null,
        fecha_llegada_cargue: null,
        fecha_salida_cargue: null,
        fecha_llegada_campo: null,
        fecha_salida_campo: null,
        estado: null,
        acto: "",
        bono_flag: null,
        bono: null,
      },
      sitioOrigen: [],
      sitioDestino: [],
      vehiculo: [],
      conductor: [],
      remolque: [],
      ruta: [],
      transportadora: [],
      producto: [],
      accion: null,
      empresa: null,
      horaCargue: null,
      fechaCargue: null,
      actoTicket: null,
      sitio_type: null,
      sitio_id: null,
      tipo_control: null,
      listasForms: {
        sitiosOrigenes: [],
        sitiosDestinos: [],
        sellos: [],
        guias: [],
        empresas: [],
        tipos_productos: [],
        horasAdicionales: [],
        tipo_producto: [],
        producto_transportado: [],
      },
      estadoFecha: null,
      estadoTicket: null,
      modalCalc: false,
      max_barriles: 0,
    };
  },
  validations: {
    formEnvio: {
      tipo_producto: {
        required,
      },
      sitio_origen_id: {
        required,
      },
      sitio_destino_id: {
        required,
      },
      producto_id: {
        required,
      },
      fecha_llegada_cargue: {
        required,
        dateFormat,
      },
      barriles_cargue: {
        required,
      },
      porc_solidos: {
        required,
      },
      porc_crudo: {
        required,
      },
      porc_agua_libre: {
        required,
      },
      observaciones: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(199),
      },
    },
    // fechasForm.cargue.hora
    fechasForm: {
      cargue: {
        hora: {
          timeFormat,
        },
      },
      salida_cargue: {
        hora: {
          timeFormat,
        },
      },
      llegada_campo: {
        hora: {
          timeFormat,
        },
      },
      salida_campo: {
        hora: {
          timeFormat,
        },
      },
    },
  },
  methods: {
    async init() {
      this.accion = this.$route.params.accion;
      this.sitio_type = this.$route.params.sitio_type;
      this.sitio_id = this.$route.params.sitio_id;
      this.tipo_control = this.$route.params.tipo_control;
      if (this.accion == "Diligenciar") {
        this.form = this.$route.params.data_edit;
        this.formEnvio.acto = 1;
        this.formEnvio.bono_flag = true;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.estado = 1;
        this.estadoTicket = this.formEnvio.estado;
        if (this.form.vehiculo.tipo_vehiculo_id !== null) {
          this.formEnvio.tipo_vehiculo_id = this.form.vehiculo.tipo_vehiculo_id;
        }

        //this.formEnvio.tipo_producto = this.form.tipo_producto;
        this.formEnvio.tipo_producto = this.form.tipo_producto.tipo_producto;
        this.formEnvio.user_id = this.$store.getters.getUser.id;
        this.max_barriles = this.form.volumen_calculado;
        await this.getTipoProductos();
        await this.getProductos();
        await this.getHorasAdicionales();
        await this.buscarSellos();
        // await this.getNumeroTicket();
        /* await this.buscarTicket(); */
        this.sitioOrigen = this.form.sitio_cargue;
        this.formEnvio.bloque_id = this.sitioOrigen.bloque_id;
        this.formEnvio.sitio_origen_id = this.form.sitio_cargue.id;
        this.conductor = {
          id: this.form.conductor.id,
          nombre:
            this.form.conductor.apellidos + " " + this.form.conductor.nombres,
          numero_documento: this.form.conductor.numero_documento,
        };
        this.vehiculo = {
          id: this.form.vehiculo.id,
          placa: this.form.vehiculo.placa,
        };
        if (this.form.remolque !== null) {
          this.remolque = {
            id: this.form.remolque.id,
            placa: this.form.remolque.placa,
          };
          this.formEnvio.remolque_id = this.remolque.id;
        }
        this.transportadora = {
          id: this.form.transportadora.id,
          razon_social: this.form.transportadora.razon_social,
        };

        // this.formEnvio.fecha_cargue = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.cargue.fecha = moment().format("YYYY-MM-DD");
        this.fechasForm.cargue.hora = moment().format("HH:mm:ss");
        this.getFechaFormEnvio(this.fechasForm.cargue, "fecha_cargue");

        if (this.form.det_postulacion_id) {
          this.formEnvio.fecha_cita_cargue = this.form.cita_cargue;
        } else {
          this.formEnvio.fecha_cita_cargue = this.form.trazas_fechas_viaje.fecha_solicitud;
        }
        this.form.ciudadOrigen = this.form.sitio_cargue.ciudad.nombre;
        this.formEnvio.ciudad_origen_id = this.form.sitio_cargue.ciudad.id;
        this.formEnvio.remision = this.form.orden_de_cargue;
        this.formEnvio.conductor_id = this.conductor.id;
        this.formEnvio.cedula_conductor = this.conductor.numero_documento;
        this.formEnvio.vehiculo_id = this.vehiculo.id;
        this.formEnvio.transportadora_id = this.transportadora.id;
        this.formEnvio.producto_id = this.producto.id;
        this.formEnvio.tipo_act = 2;
      } else if (this.accion == "Show") {
        await this.getHorasAdicionales();
        await this.getTipoProductos();
        this.formEnvio.acto = 2;
        this.estadoTicket = this.formEnvio.estado;
        this.formEnvio.horas_vigencia =
          this.formEnvio.horas_vigencia + " Horas";
        this.cargando = false;
      } else if (this.accion == "Cambiar") {
        let viaje = this.$route.params.data_edit;
        this.formEnvio = this.$route.params.data_edit.ticket;
        this.formEnvio.acto = 3;
        this.estadoTicket = this.formEnvio.estado;
        this.form = this.$route.params.data_edit;
        //this.max_barriles = this.form.volumen_calculado;
        this.form.sitio_cargue_id = this.formEnvio.sitio_origen.id;
        this.form.ciudadOrigen = this.formEnvio.ciudad_origen.nombre;
        this.form.ciudadDestino = this.formEnvio.ciudad_destino.nombre;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        await this.getTipoProductos();
        await this.getProductos();
        await this.getHorasAdicionales();
        await this.buscarSellos();
        this.sitioDestino = this.formEnvio.sitio_destino;
        this.sitioOrigen = this.formEnvio.sitio_origen;
        this.formEnvio.bloque_id = this.sitioOrigen.bloque_id;
        this.conductor = this.form.conductor;
        this.conductor.nombre = `${this.form.conductor.nombres} ${this.form.conductor.apellidos}`;
        this.vehiculo = this.form.vehiculo;
        if (this.form.remolque !== null) {
          this.remolque = this.form.remolque;
        }
        this.transportadora = this.form.transportadora;
        // this.formEnvio.fecha_cargue = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.cargue.fecha = moment(
          this.formEnvio.fecha_cargue
        ).format("YYYY-MM-DD");
        this.fechasForm.cargue.hora = moment(
          this.formEnvio.fecha_cargue
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(this.fechasForm.cargue, "fecha_cargue");
        // this.formEnvio.fecha_llegada_campo = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.llegada_campo.fecha = moment(
          this.formEnvio.fecha_llegada_campo
        ).format("YYYY-MM-DD");
        this.fechasForm.llegada_campo.hora = moment(
          this.formEnvio.fecha_llegada_campo
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.llegada_campo,
          "fecha_llegada_campo"
        );
        // this.formEnvio.fecha_llegada_cargue = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.llegada_cargue.fecha = moment(
          this.formEnvio.fecha_llegada_cargue
        ).format("YYYY-MM-DD");
        this.fechasForm.llegada_cargue.hora = moment(
          this.formEnvio.fecha_llegada_cargue
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.llegada_cargue,
          "fecha_llegada_cargue"
        );
        // this.formEnvio.fecha_salida_campo = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.salida_campo.fecha = moment(
          this.formEnvio.fecha_salida_campo
        ).format("YYYY-MM-DD");
        this.fechasForm.salida_campo.hora = moment(
          this.formEnvio.fecha_salida_campo
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.salida_campo,
          "fecha_salida_campo"
        );
        // this.formEnvio.fecha_salida_cargue = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.salida_cargue.fecha = moment(
          this.formEnvio.fecha_salida_cargue
        ).format("YYYY-MM-DD");
        this.fechasForm.salida_cargue.hora = moment(
          this.formEnvio.fecha_salida_cargue
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.salida_cargue,
          "fecha_salida_cargue"
        );
        this.formEnvio.tipo_act = 3;
      } else if (this.accion == "Actualizar") {
        let ticket = this.$route.params.data_edit.ticket;
        this.formEnvio = ticket;
        this.formEnvio.acto = 2;
        this.estadoTicket = this.formEnvio.estado;
        this.form = this.$route.params.data_edit;
        this.max_barriles = this.form.volumen_calculado;
        this.form.sitio_cargue_id = this.formEnvio.sitio_origen.id;
        this.form.ciudadOrigen = this.formEnvio.ciudad_origen.nombre;
        this.form.ciudadDestino = this.formEnvio.ciudad_destino.nombre;
        this.$swal({
          text:
            "Por favor verifique el nombre del conductor y las placas del cabezote y el trailer!!",
          icon: "info",
          confirmButtonText: "Aceptar!",
        });
        await this.getTipoProductos();
        await this.getProductos();
        await this.getHorasAdicionales();
        await this.buscarSellos();
        this.sitioDestino = this.formEnvio.sitio_destino;
        this.sitioOrigen = this.formEnvio.sitio_origen;
        this.formEnvio.bloque_id = this.sitioOrigen.bloque_id;
        this.conductor = this.form.conductor;
        this.conductor.nombre = `${this.form.conductor.nombres} ${this.form.conductor.apellidos}`;
        this.vehiculo = this.form.vehiculo;
        if (this.form.remolque !== null) {
          this.remolque = this.form.remolque;
        }
        this.transportadora = this.form.transportadora;

        // this.formEnvio.fecha_cargue = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.cargue.fecha = moment(
          this.formEnvio.fecha_cargue
        ).format("YYYY-MM-DD");
        this.fechasForm.cargue.hora = moment(
          this.formEnvio.fecha_cargue
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(this.fechasForm.cargue, "fecha_cargue");
        // this.formEnvio.fecha_llegada_campo = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.llegada_campo.fecha = moment(
          this.formEnvio.fecha_llegada_campo
        ).format("YYYY-MM-DD");
        this.fechasForm.llegada_campo.hora = moment(
          this.formEnvio.fecha_llegada_campo
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.llegada_campo,
          "fecha_llegada_campo"
        );

        // this.formEnvio.fecha_llegada_cargue = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.llegada_cargue.fecha = moment(
          this.formEnvio.fecha_llegada_cargue
        ).format("YYYY-MM-DD");
        this.fechasForm.llegada_cargue.hora = moment(
          this.formEnvio.fecha_llegada_cargue
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.llegada_cargue,
          "fecha_llegada_cargue"
        );

        // this.formEnvio.fecha_salida_campo = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.salida_campo.fecha = moment(
          this.formEnvio.fecha_salida_campo
        ).format("YYYY-MM-DD");
        this.fechasForm.salida_campo.hora = moment(
          this.formEnvio.fecha_salida_campo
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.salida_campo,
          "fecha_salida_campo"
        );

        // this.formEnvio.fecha_salida_cargue = moment().format("YYYY-MM-DDTHH:mm:ss");
        this.fechasForm.salida_cargue.fecha = moment(
          this.formEnvio.fecha_salida_cargue
        ).format("YYYY-MM-DD");
        this.fechasForm.salida_cargue.hora = moment(
          this.formEnvio.fecha_salida_cargue
        ).format("HH:mm:ss");
        this.getFechaFormEnvio(
          this.fechasForm.salida_cargue,
          "fecha_salida_cargue"
        );
        this.formEnvio.tipo_act = 4;
      } else {
        this.cargando = false;
        this.id = this.$route.params.id;
      }
    },

    getTipoProductos() {
      axios.get("/api/lista/7").then((response) => {
        this.listasForms.tipo_producto = response.data;
      });
    },

    getProductos() {
      axios
        .get("/api/admin/productos/lista", {
          params: {
            linea_negocio_id: [2],
          },
        })
        .then((response) => {
          this.listasForms.producto_transportado = response.data;
        });
    },

    validarBarriles() {
      if (this.formEnvio.barriles_cargue > this.max_barriles) {
        this.$swal({
          text:
            "La cantidad de barriles no puede superar " +
            this.max_barriles +
            " barriles",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.formEnvio.barriles_cargue = null;
      }
    },

    checkFecha() {
      this.estadoFecha = null;
      if (
        this.formEnvio.fecha_cargue.slice(0, 10) >
        this.formEnvio.fecha_salida_cargue(0, 10)
      ) {
        this.$swal({
          title: "Revisar Fechas!!",
          text: "La fecha de cargue no puede ser mayor a la fecha de salida",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.estadoFecha = 1;
      } else {
        if (
          this.formEnvio.fecha_cargue.slice(11, 19) >
          this.formEnvio.fecha_salida_cargue.slice(11, 19)
        ) {
          this.$swal({
            title: "Revisar Horas!!",
            text:
              "La hora de cargue no puede ser mayor a la hora de salida de cargue",
            icon: "warning",
            confirmButtonText: "Aceptar!",
          });
          this.estadoFecha = 2;
        }
      }
    },

    buscarSellos() {
      let me = this;
      me.cargando = true;
      let url = "api/hidrocarburos/sellos/lista";
      let tipo_empresa = 1;

      if (me.terceroSellos == true) {
        tipo_empresa = 2;
      }
      axios
        .get(url, {
          params: {
            tipo_empresa,
            sitio_id: me.form.sitio_cargue_id,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sellos = respuesta;
          me.formEnvio.sello_id = me.listasForms.sellos.id;
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getHorasAdicionales() {
      axios.get("/api/lista/55").then((response) => {
        this.listasForms.horasAdicionales = response.data;
      });
    },

    async saveDetTicket() {
      var confirm = true;
      if (this.formEnvio.tipo_act !== undefined) {
        if (this.formEnvio.tipo_act == 3) {
          confirm = false;
          await this.$swal({
            title: "Esta seguro de anular y cambiar el ticket?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Anular y cambiar!",
          }).then((result) => {
            if (result.value) {
              confirm = true;
            }
          });
        }
      }

      if (confirm) {
        this.formEnvio.viaje_id = this.form.id;
        if (!this.$v.formEnvio.$invalid) {
          if (!this.formEnvio.hora_adicional && this.formEnvio.tipo_act != 4) {
            this.$swal({
              title: "Hora Adicional",
              text: "Esta seguro de que no desea asignar horas adicionales?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, Aceptar!",
            }).then((result) => {
              if (result.value) {
                if (this.estadoFecha == 1 || this.estadoFecha == 2) {
                  this.$swal({
                    title: "Revisar Fechas!!",
                    text:
                      "La fecha y hora de cargue no puede ser menor a la fecha y hora de expedición",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Aceptar!",
                  }).then((result) => {
                    if (result.value) {
                      this.save();
                    }
                  });
                } else {
                  this.save();
                }
              }
            });
          } else {
            if (this.estadoFecha == 1 || this.estadoFecha == 2) {
              this.$swal({
                title: "Revisar Fechas!!",
                text:
                  "La fecha y hora de cargue no puede ser menor a la fecha y hora de expedición",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar!",
              }).then((result) => {
                if (result.value) {
                  this.save();
                }
              });
            } else {
              this.save();
            }
          }
        }
      }
    },

    async save() {
      this.cargando = true;
      if (this.formEnvio.tipo_act !== 4) {
        this.estadoTicket = 3;
      }

      await axios({
        method: "POST",
        url: "/api/hidrocarburos/detalleTickets",
        data: this.formEnvio,
      })
        .then((response) => {
          let html = ``;
          if (this.formEnvio.tipo_act !== 4) {
            this.$route.params.accion = "Show";
            this.accion = "Show";
            this.formEnvio = response.data.det_ticket;
            this.$route.params.data_edit = this.formEnvio;
            //this.formEnvio.numero_ticket = response.data.num_ticket;
            //this.formEnvio.id = response.data.id;
            if (response.data.det_ticket.bono) {
              this.formEnvio.bono = response.data.det_ticket.bono;
              this.form.bono = response.data.det_ticket.bono;
              html = `
                <h3>Felicitaciones..</h3>
                Este ticket aplica para bono, ahora la podrás imprimir para reclamarlo.
              `;
            }
            this.init();
          }
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            html: html,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch(async (e) => {
          let res = await Object.assign({}, e);
          let err = res.response.data.msg;
          this.$swal({
            icon: "error",
            title: "Ha ocurrido un error - " + err,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    buscarSitioOrigen(search) {
      if (search != "") {
        let me = this;
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitiosOrigenes = respuesta;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarSitioDestino(search) {
      if (search != "") {
        let me = this;
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitiosDestinos = respuesta;
          })
          .catch(function(error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitioOrigen() {
      // Se reinician las variables a modificar del formulario
      this.form.ciudadOrigen = null;
      this.formEnvio.sitio_origen_id = null;
      this.sitioDestino = null;
      this.formEnvio.sitio_destino_id = null;
      this.listasForms.producto_liquido = [];
      this.formEnvio.producto_id = null;

      if (this.sitioOrigen) {
        this.formEnvio.sitio_origen_id = this.sitioOrigen.id;
        this.formEnvio.bloque_id = this.sitioOrigen.bloque_id;
        this.form.sitio_cargue_id = this.sitioOrigen.id;
        this.form.ciudadOrigen = this.sitioOrigen.ciudad.nombre;
        this.getProductos();
        if (this.formEnvio.tipo_act !== 4) {
          this.buscarSellos();
        }
      }
    },

    async selectSitioDestino() {
      if (this.sitioDestino) {
        await this.checkRuta();
      } else {
        this.formEnvio.sitio_destino_id = null;
        this.formEnvio.ciudad_destino_id = null;
        this.formEnvio.codigo_destino = null;
        this.formEnvio.direccion_destino = null;
        this.formEnvio.horas_vigencia = null;
        this.form.ciudadDestino = null;
      }
    },

    async checkRuta() {
      let me = this;
      me.cargando = true;
      var url =
        "api/hidrocarburos/detalleGuias/checkRoute?sitio_origen_id=" +
        this.formEnvio.sitio_origen_id +
        "&sitio_destino_id=" +
        this.sitioDestino.id +
        "&viaje_id=" +
        this.form.id;
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          let msg_error = "";
          if (respuesta.ruta !== null) {
            let ruta = respuesta.ruta;
            me.ruta = {
              id: ruta.id,
              horas_vigencia: ruta.horas_vigencia + " Horas",
            };
          } else {
            msg_error +=
              "Ruta no encontrada, por favor seleccione otro destino.";
            me.ruta = {
              id: null,
              horas_vigencia: null,
            };
          }

          if (!msg_error) {
            me.form.ciudadDestino = me.sitioDestino.ciudad.nombre;
            me.formEnvio.ruta_id = me.ruta.id;
            me.formEnvio.ciudad_destino_id = me.sitioDestino.ciudad_id;
            me.formEnvio.direccion_destino = me.sitioDestino.dirreccion;
            me.formEnvio.codigo_destino = me.sitioDestino.ciudad.cod_dane;
            me.formEnvio.horas_vigencia = me.ruta.horas_vigencia;
            me.form.despachado_a_id = me.ruta.despachado_a_id;
            me.formEnvio.despachado_a_id = me.ruta.despachado_a_id;
            me.formEnvio.sitio_destino_id = me.sitioDestino.id;
          } else {
            me.$swal({
              icon: "error",
              title: msg_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            me.sitioDestino = [];
            me.formEnvio.sitio_destino_id = null;
          }
          me.cargando = false;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error... " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.cargando = false;
        });
    },

    back() {
      return this.$router.replace({
        path: `/Hidrocarburos/VentanasOperaciones/${this.sitio_id}/${this.sitio_type}/${this.tipo_control}`,
      });
    },

    async print() {
      this.form = this.$route.params.data_edit;
      this.estadoTicket = 4;
      //if (this.accion == "Actualizar") {
      //this.formEnvio.id = this.form.ticket_id;
      //}
      this.cargando = true;
      await axios({
        method: "get",
        url: "/api/hidrocarburos/detalleTickets/pdf/" + this.formEnvio.id,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    printBono(viaje_id) {
      axios
        .get(`/api/hidrocarburos/bonoGuia/printBono`, {
          params: { viaje_id },
        })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getFechaFormEnvio(fechas, campo) {
      switch (campo) {
        case "fecha_cargue":
          this.formEnvio.fecha_cargue = null;
          if (fechas.fecha && fechas.hora) {
            this.formEnvio.fecha_cargue = `${fechas.fecha} ${fechas.hora}`;
          }
          break;

        case "fecha_llegada_cargue":
          this.formEnvio.fecha_llegada_cargue = null;
          if (fechas.fecha && fechas.hora) {
            this.formEnvio.fecha_llegada_cargue = `${fechas.fecha} ${fechas.hora}`;
          }
          break;

        case "fecha_salida_cargue":
          this.formEnvio.fecha_salida_cargue = null;
          if (fechas.fecha && fechas.hora) {
            this.formEnvio.fecha_salida_cargue = `${fechas.fecha} ${fechas.hora}`;
          }
          break;

        case "fecha_llegada_campo":
          this.formEnvio.fecha_llegada_campo = null;
          if (
            fechas.fecha &&
            fechas.hora &&
            fechas.fecha != "Invalid date" &&
            fechas.hora != "Invalid date"
          ) {
            this.formEnvio.fecha_llegada_campo = `${fechas.fecha} ${fechas.hora}`;
          }
          break;

        case "fecha_salida_campo":
          this.formEnvio.fecha_salida_campo = null;
          if (
            fechas.fecha &&
            fechas.hora &&
            fechas.fecha != "Invalid date" &&
            fechas.hora != "Invalid date"
          ) {
            this.formEnvio.fecha_salida_campo = `${fechas.fecha} ${fechas.hora}`;
          }
          break;

        default:
          break;
      }
    },

    validarFechas() {
      if (
        this.formEnvio.fecha_llegada_cargue &&
        this.formEnvio.fecha_salida_cargue
      ) {
        if (
          this.formEnvio.fecha_llegada_cargue >
          this.formEnvio.fecha_salida_cargue
        ) {
          this.formEnvio.fecha_salida_cargue = null;
          this.fechasForm.salida_cargue.fecha = null;
          this.fechasForm.salida_cargue.hora = null;
          this.$swal({
            icon: "error",
            title: `La fecha de cargue no puede ser mayor a la fecha salida cargue..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },
  async mounted() {
    this.cargando = true;
    await this.init();
  },
};
</script>
